export default {
  "code[class*=\"language-\"]": {
    "color": "#111b27",
    "background": "none",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
    "color": "#111b27",
    "background": "#e3e9f2",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "padding": "1em",
    "margin": "0.5em 0",
    "overflow": "auto"
  },
  "pre[class*=\"language-\"]::-moz-selection": {
    "background": "#ccd6e4"
  },
  "pre[class*=\"language-\"] ::-moz-selection": {
    "background": "#ccd6e4"
  },
  "code[class*=\"language-\"]::-moz-selection": {
    "background": "#ccd6e4"
  },
  "code[class*=\"language-\"] ::-moz-selection": {
    "background": "#ccd6e4"
  },
  "pre[class*=\"language-\"]::selection": {
    "background": "#ccd6e4"
  },
  "pre[class*=\"language-\"] ::selection": {
    "background": "#ccd6e4"
  },
  "code[class*=\"language-\"]::selection": {
    "background": "#ccd6e4"
  },
  "code[class*=\"language-\"] ::selection": {
    "background": "#ccd6e4"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "background": "#e3e9f2",
    "padding": "0.1em 0.3em",
    "borderRadius": "0.3em",
    "whiteSpace": "normal"
  },
  "comment": {
    "color": "#304259",
    "fontStyle": "italic"
  },
  "prolog": {
    "color": "#304259",
    "fontStyle": "italic"
  },
  "doctype": {
    "color": "#304259",
    "fontStyle": "italic"
  },
  "cdata": {
    "color": "#304259",
    "fontStyle": "italic"
  },
  "punctuation": {
    "color": "#111b27"
  },
  "selector": {
    "color": "#007474"
  },
  "tag": {
    "color": "#007474"
  },
  "attr-name": {
    "color": "#7d6600"
  },
  "boolean": {
    "color": "#7d6600"
  },
  "number": {
    "color": "#7d6600"
  },
  "constant": {
    "color": "#7d6600"
  },
  "pseudo-class": {
    "color": "#7d6600"
  },
  "pseudo-element": {
    "color": "#7d6600"
  },
  "selector.attribute": {
    "color": "#7d6600"
  },
  "class-name": {
    "color": "#005c99"
  },
  "key": {
    "color": "#005c99"
  },
  "parameter": {
    "color": "#005c99"
  },
  "property": {
    "color": "#005c99"
  },
  "property-access": {
    "color": "#005c99"
  },
  "variable": {
    "color": "#005c99"
  },
  "attr-value": {
    "color": "#237800"
  },
  "inserted": {
    "color": "#237800"
  },
  "color": {
    "color": "#237800"
  },
  "selector.value": {
    "color": "#237800"
  },
  "string": {
    "color": "#237800"
  },
  "string.url-link": {
    "color": "#237800"
  },
  "builtin": {
    "color": "#b800b8"
  },
  "keyword-array": {
    "color": "#b800b8"
  },
  "package": {
    "color": "#b800b8"
  },
  "regex": {
    "color": "#b800b8"
  },
  "function": {
    "color": "#8600c6"
  },
  "selector.class": {
    "color": "#8600c6"
  },
  "selector.id": {
    "color": "#8600c6"
  },
  "atrule.rule": {
    "color": "#aa4d00"
  },
  "combinator": {
    "color": "#aa4d00"
  },
  "keyword": {
    "color": "#aa4d00"
  },
  "operator": {
    "color": "#aa4d00"
  },
  "unit": {
    "color": "#aa4d00"
  },
  "deleted": {
    "color": "#bf0100"
  },
  "important": {
    "color": "#bf0100",
    "fontWeight": "bold"
  },
  "keyword-this": {
    "color": "#005c99",
    "fontWeight": "bold"
  },
  "this": {
    "color": "#005c99",
    "fontWeight": "bold"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "entity": {
    "cursor": "help"
  },
  ".language-markdown .token.title": {
    "color": "#005c99",
    "fontWeight": "bold"
  },
  ".language-markdown .token.title .token.punctuation": {
    "color": "#005c99",
    "fontWeight": "bold"
  },
  ".language-markdown .token.code": {
    "color": "#007474"
  },
  ".language-markdown .token.url > .token.content": {
    "color": "#237800"
  },
  ".language-markdown .token.url-link": {
    "color": "#7d6600"
  },
  ".language-markdown .token.list.punctuation": {
    "color": "#b800b8"
  },
  ".language-markdown .token.table-header": {
    "color": "#111b27"
  },
  ".language-json .token.operator": {
    "color": "#111b27"
  },
  "tab:not(:empty):before": {
    "color": "#304259"
  },
  "cr:before": {
    "color": "#304259"
  },
  "lf:before": {
    "color": "#304259"
  },
  "space:before": {
    "color": "#304259"
  },
  "div.code-toolbar > .toolbar a": {
    "color": "#e3e9f2",
    "background": "#005c99"
  },
  "div.code-toolbar > .toolbar button": {
    "color": "#e3e9f2",
    "background": "#005c99"
  },
  "div.code-toolbar > .toolbar a:hover": {
    "color": "#e3e9f2",
    "background": "#005c99da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar a:focus": {
    "color": "#e3e9f2",
    "background": "#005c99da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar button:hover": {
    "color": "#e3e9f2",
    "background": "#005c99da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar button:focus": {
    "color": "#e3e9f2",
    "background": "#005c99da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar span": {
    "color": "#e3e9f2",
    "background": "#304259"
  },
  "div.code-toolbar > .toolbar span:hover": {
    "color": "#e3e9f2",
    "background": "#304259"
  },
  "div.code-toolbar > .toolbar span:focus": {
    "color": "#e3e9f2",
    "background": "#304259"
  },
  ".line-highlight": {
    "background": "linear-gradient(to right, #3042591f 70%, #30425915)"
  },
  ".line-highlight:before": {
    "backgroundColor": "#304259",
    "color": "#e3e9f2",
    "boxShadow": "0 1px #ccd6e4"
  },
  ".line-highlight[data-end]:after": {
    "backgroundColor": "#304259",
    "color": "#e3e9f2",
    "boxShadow": "0 1px #ccd6e4"
  },
  "pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before": {
    "backgroundColor": "#3042591f"
  },
  ".line-numbers .line-numbers-rows": {
    "borderRight": "1px solid #111b271f",
    "background": "#d8e0ebda"
  },
  ".line-numbers-rows > span:before": {
    "color": "#111b2796"
  },
  ".rainbow-braces .token.punctuation.brace-level-1": {
    "color": "#7d6600"
  },
  ".rainbow-braces .token.punctuation.brace-level-5": {
    "color": "#7d6600"
  },
  ".rainbow-braces .token.punctuation.brace-level-9": {
    "color": "#7d6600"
  },
  ".rainbow-braces .token.punctuation.brace-level-2": {
    "color": "#b800b8"
  },
  ".rainbow-braces .token.punctuation.brace-level-6": {
    "color": "#b800b8"
  },
  ".rainbow-braces .token.punctuation.brace-level-10": {
    "color": "#b800b8"
  },
  ".rainbow-braces .token.punctuation.brace-level-3": {
    "color": "#005c99"
  },
  ".rainbow-braces .token.punctuation.brace-level-7": {
    "color": "#005c99"
  },
  ".rainbow-braces .token.punctuation.brace-level-11": {
    "color": "#005c99"
  },
  ".rainbow-braces .token.punctuation.brace-level-4": {
    "color": "#8600c6"
  },
  ".rainbow-braces .token.punctuation.brace-level-8": {
    "color": "#8600c6"
  },
  ".rainbow-braces .token.punctuation.brace-level-12": {
    "color": "#8600c6"
  },
  "pre.diff-highlight > code .token.deleted:not(.prefix)": {
    "backgroundColor": "#bf01001f"
  },
  "pre > code.diff-highlight .token.deleted:not(.prefix)": {
    "backgroundColor": "#bf01001f"
  },
  "pre.diff-highlight > code .token.inserted:not(.prefix)": {
    "backgroundColor": "#2378001f"
  },
  "pre > code.diff-highlight .token.inserted:not(.prefix)": {
    "backgroundColor": "#2378001f"
  },
  ".command-line-prompt": {
    "borderRight": "1px solid #111b271f"
  },
  ".command-line-prompt > span:before": {
    "color": "#111b2796"
  }
};