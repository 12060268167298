export default {
  "code[class*=\"language-\"]": {
    "color": "#e3e9f2",
    "background": "none",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
    "color": "#e3e9f2",
    "background": "#111b27",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "padding": "1em",
    "margin": "0.5em 0",
    "overflow": "auto"
  },
  "pre[class*=\"language-\"]::-moz-selection": {
    "background": "#304259"
  },
  "pre[class*=\"language-\"] ::-moz-selection": {
    "background": "#304259"
  },
  "code[class*=\"language-\"]::-moz-selection": {
    "background": "#304259"
  },
  "code[class*=\"language-\"] ::-moz-selection": {
    "background": "#304259"
  },
  "pre[class*=\"language-\"]::selection": {
    "background": "#304259"
  },
  "pre[class*=\"language-\"] ::selection": {
    "background": "#304259"
  },
  "code[class*=\"language-\"]::selection": {
    "background": "#304259"
  },
  "code[class*=\"language-\"] ::selection": {
    "background": "#304259"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "background": "#111b27",
    "padding": "0.1em 0.3em",
    "borderRadius": "0.3em",
    "whiteSpace": "normal"
  },
  "comment": {
    "color": "#ccd6e4",
    "fontStyle": "italic"
  },
  "prolog": {
    "color": "#ccd6e4",
    "fontStyle": "italic"
  },
  "doctype": {
    "color": "#ccd6e4",
    "fontStyle": "italic"
  },
  "cdata": {
    "color": "#ccd6e4",
    "fontStyle": "italic"
  },
  "punctuation": {
    "color": "#e3e9f2"
  },
  "selector": {
    "color": "#5dc2c2"
  },
  "tag": {
    "color": "#5dc2c2"
  },
  "attr-name": {
    "color": "#cdb74a"
  },
  "boolean": {
    "color": "#cdb74a"
  },
  "number": {
    "color": "#cdb74a"
  },
  "constant": {
    "color": "#cdb74a"
  },
  "pseudo-class": {
    "color": "#cdb74a"
  },
  "pseudo-element": {
    "color": "#cdb74a"
  },
  "selector.attribute": {
    "color": "#cdb74a"
  },
  "class-name": {
    "color": "#6ab3e4"
  },
  "key": {
    "color": "#6ab3e4"
  },
  "parameter": {
    "color": "#6ab3e4"
  },
  "property": {
    "color": "#6ab3e4"
  },
  "property-access": {
    "color": "#6ab3e4"
  },
  "variable": {
    "color": "#6ab3e4"
  },
  "attr-value": {
    "color": "#82c366"
  },
  "inserted": {
    "color": "#82c366"
  },
  "color": {
    "color": "#82c366"
  },
  "selector.value": {
    "color": "#82c366"
  },
  "string": {
    "color": "#82c366"
  },
  "string.url-link": {
    "color": "#82c366"
  },
  "builtin": {
    "color": "#ea89ea"
  },
  "keyword-array": {
    "color": "#ea89ea"
  },
  "package": {
    "color": "#ea89ea"
  },
  "regex": {
    "color": "#ea89ea"
  },
  "function": {
    "color": "#cf7ef6"
  },
  "selector.class": {
    "color": "#cf7ef6"
  },
  "selector.id": {
    "color": "#cf7ef6"
  },
  "atrule.rule": {
    "color": "#d88b4a"
  },
  "combinator": {
    "color": "#d88b4a"
  },
  "keyword": {
    "color": "#d88b4a"
  },
  "operator": {
    "color": "#d88b4a"
  },
  "selector.token.operator": {
    "color": "#d88b4a"
  },
  "unit": {
    "color": "#d88b4a"
  },
  "deleted": {
    "color": "#f57a73"
  },
  "important": {
    "color": "#f57a73",
    "fontWeight": "bold"
  },
  "keyword-this": {
    "color": "#6ab3e4",
    "fontWeight": "bold"
  },
  "this": {
    "color": "#6ab3e4",
    "fontWeight": "bold"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "entity": {
    "cursor": "help"
  },
  ".language-markdown .token.title": {
    "color": "#6ab3e4",
    "fontWeight": "bold"
  },
  ".language-markdown .token.title .token.punctuation": {
    "color": "#6ab3e4",
    "fontWeight": "bold"
  },
  ".language-markdown .token.code": {
    "color": "#5dc2c2"
  },
  ".language-markdown .token.url .token.content": {
    "color": "#82c366"
  },
  ".language-markdown .token.url-link": {
    "color": "#cdb74a"
  },
  ".language-markdown .token.list.punctuation": {
    "color": "#ea89ea"
  },
  ".language-markdown .token.table-header": {
    "color": "#e3e9f2"
  },
  ".language-json .token.operator": {
    "color": "#e3e9f2"
  },
  "tab:not(:empty):before": {
    "color": "#ccd6e4"
  },
  "cr:before": {
    "color": "#ccd6e4"
  },
  "lf:before": {
    "color": "#ccd6e4"
  },
  "space:before": {
    "color": "#ccd6e4"
  },
  "div.code-toolbar > .toolbar a": {
    "color": "#111b27",
    "background": "#6ab3e4"
  },
  "div.code-toolbar > .toolbar button": {
    "color": "#111b27",
    "background": "#6ab3e4"
  },
  "div.code-toolbar > .toolbar a:hover": {
    "color": "#111b27",
    "background": "#6ab3e4da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar a:focus": {
    "color": "#111b27",
    "background": "#6ab3e4da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar button:hover": {
    "color": "#111b27",
    "background": "#6ab3e4da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar button:focus": {
    "color": "#111b27",
    "background": "#6ab3e4da",
    "textDecoration": "none"
  },
  "div.code-toolbar > .toolbar span": {
    "color": "#111b27",
    "background": "#ccd6e4"
  },
  "div.code-toolbar > .toolbar span:hover": {
    "color": "#111b27",
    "background": "#ccd6e4"
  },
  "div.code-toolbar > .toolbar span:focus": {
    "color": "#111b27",
    "background": "#ccd6e4"
  },
  ".line-highlight": {
    "background": "linear-gradient(to right, #ccd6e41f 70%, #ccd6e415)"
  },
  ".line-highlight:before": {
    "backgroundColor": "#ccd6e4",
    "color": "#111b27",
    "boxShadow": "0 1px #304259"
  },
  ".line-highlight[data-end]:after": {
    "backgroundColor": "#ccd6e4",
    "color": "#111b27",
    "boxShadow": "0 1px #304259"
  },
  "pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before": {
    "backgroundColor": "#ccd6e418"
  },
  ".line-numbers .line-numbers-rows": {
    "borderRight": "1px solid #0b121b",
    "background": "#0b121b7a"
  },
  ".line-numbers-rows > span:before": {
    "color": "#e3e9f296"
  },
  ".rainbow-braces .token.punctuation.brace-level-1": {
    "color": "#cdb74a"
  },
  ".rainbow-braces .token.punctuation.brace-level-5": {
    "color": "#cdb74a"
  },
  ".rainbow-braces .token.punctuation.brace-level-9": {
    "color": "#cdb74a"
  },
  ".rainbow-braces .token.punctuation.brace-level-2": {
    "color": "#ea89ea"
  },
  ".rainbow-braces .token.punctuation.brace-level-6": {
    "color": "#ea89ea"
  },
  ".rainbow-braces .token.punctuation.brace-level-10": {
    "color": "#ea89ea"
  },
  ".rainbow-braces .token.punctuation.brace-level-3": {
    "color": "#6ab3e4"
  },
  ".rainbow-braces .token.punctuation.brace-level-7": {
    "color": "#6ab3e4"
  },
  ".rainbow-braces .token.punctuation.brace-level-11": {
    "color": "#6ab3e4"
  },
  ".rainbow-braces .token.punctuation.brace-level-4": {
    "color": "#cf7ef6"
  },
  ".rainbow-braces .token.punctuation.brace-level-8": {
    "color": "#cf7ef6"
  },
  ".rainbow-braces .token.punctuation.brace-level-12": {
    "color": "#cf7ef6"
  },
  "pre.diff-highlight > code .token.deleted:not(.prefix)": {
    "backgroundColor": "#f57a731f"
  },
  "pre > code.diff-highlight .token.deleted:not(.prefix)": {
    "backgroundColor": "#f57a731f"
  },
  "pre.diff-highlight > code .token.inserted:not(.prefix)": {
    "backgroundColor": "#82c3661f"
  },
  "pre > code.diff-highlight .token.inserted:not(.prefix)": {
    "backgroundColor": "#82c3661f"
  },
  ".command-line-prompt": {
    "borderRight": "1px solid #0b121b"
  },
  ".command-line-prompt > span:before": {
    "color": "#e3e9f296"
  }
};